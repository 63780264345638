<template>
    <div>
        <b-modal
        id="modal-center"
        centered
        size="lg"
        ref="showItemsInWarehouse"
        ok-only
        ok-title="Close"
        title="Items in Users Suite">
            <b-table
             sticky-header="400px"
             :bordered="true"
             :hover="true"
             responsive
             :fields="fields"
             :items="itemsInWarehouse"
            >

            <template v-slot:cell(pictures)="data" >
                <img v-if="data.item.productpictures.length>0" class="image" :src="data.item.productpictures[0].url" crossorigin="anonymous">
                <img class="image" v-else alt="No Image Found" />
            </template>

            </b-table>
        </b-modal>
    </div>
</template>

<script>
import { BModal, BTable } from 'bootstrap-vue';
import { mapActions, mapState, mapMutations } from "vuex";

export default{
    components:{
        BModal,
        BTable
    },
    computed:{
        ...mapState({
            itemsInWarehouse: state => {
		       return state.customerDetails.itemsInWarehouse
	        }
        })
    },
    methods:{
        ...mapActions({
        }),
        showModal() {
          this.$refs.showItemsInWarehouse.show();
        }
    },
    data() {
        return {
            fields: [
              { label: "", key: "pictures" },
              { label: "Item Name", key: "itemName" },
              "quantity",
              { label: "Date Received", key: "whenArrived" },
              "value",
              { label: "Merchant", key: "itemSource" },
            ],
        }
    }
}
</script>

<style scoped>
.image {
    width: 60px;
    height: auto;
}
</style>