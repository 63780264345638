<template>
	<div class="table-section" style="">
	  
		<h2><center>Customer Details</center></h2>
		<br/><br/>
		<b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1 search_bar"
          placeholder="Search..."
        />
	  
	  <b-table
		:items="customerDetails"
		responsive
		:fields="tableColumns"
		primary-key="id"
		show-empty
		style="width:87%"
		empty-text="No matching records found"
	  >
	  <template #cell(data)="data">
		<div class="text-nowrap">
		</div>
	  </template>
	  <template #cell(StatusOfEmailComfirmationLink)="data">
		<div v-if="data.item.isVerified" class="text-nowrap">
			Verified
			
		</div>
		<div v-else class="text-nowrap">
			Not Verified
		</div>
	  </template>

	  <template v-slot:cell(userName)="data" >
		{{ data.item.firstName + " "}}{{ data.item.lastName }}
	  </template>

	  <template v-slot:cell(WarehouseItems)="data" >
		<i class="fa-regular fa-eye" @click="showItems(data.item)"></i>
	  </template>

	  <template v-slot:cell(LastLoggedIn)="data" >
		<div v-if="data.item.loggedInCustomerDetails.length>0" class="text-nowrap">
			{{ data.item.loggedInCustomerDetails[0].LastLoggedIn }}
		</div>
		<div v-else class="text-nowrap">
		</div>
	  </template>

	  <template v-slot:cell(LoginStatus)="data" >
		<div v-if="data.item.loggedInCustomerDetails.length>0" class="text-nowrap">
			{{ data.item.loggedInCustomerDetails[0].LoginStatus }}
		</div>
		<div v-else class="text-nowrap">
		</div>
	  </template>

	  <template v-slot:cell(suitAddress)="data" >
		<div v-if="data.item.loggedInCustomerDetails.length>0" class="text-nowrap">
			{{ data.item.loggedInCustomerDetails[0].suitAddress }}
		</div>
		<div v-else class="text-nowrap">
		</div>
	  </template>
	  
	</b-table>
	<SuitItems ref="showItemsInWarehouse" />
	<div class="mx-2 mb-2">
	  <b-row>
		<b-col
		  cols="12"
		  sm="6"
		  class="d-flex align-items-center justify-content-center justify-content-sm-start"
		>
		</b-col>
		<b-col
		  cols="12"
		  sm="6"
		  class="d-flex align-items-center justify-content-center justify-content-sm-end"
		>
		<b-pagination
		  v-model="currentPage"
		  :total-rows="allCustomers.length"
		  :per-page="perPage"
		  first-number
		  last-number
		  class="mb-0 mt-1 mt-sm-0"
		  prev-class="prev-item"
		  next-class="next-item"
		>
		  <template #prev-text>
			<feather-icon
			  icon="ChevronLeftIcon"
			  size="18"
			/>
		  </template>
		  <template #next-text>
			<feather-icon
			  icon="ChevronRightIcon"
			  size="18"
			/>
		  </template>
		  
		</b-pagination>
	  </b-col>
	  </b-row>
	</div>
	</div>
  </template>
  <script>
  import {
	BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,BPopover
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter'
  import { mapMutations, mapActions, mapState } from "vuex";
  import BCardCode from '@core/components/b-card-code';
  import SuitItems from './suitItemsModal.vue';
  
  export default {
	name: "carouselList",
	data() {
	  return {
		perPage:10,
		currentPage : 1,
		perPageOptions : [10, 25, 50, 100],
		searchQuery:'',
		fetchCarousel:[],
		tableColumns :[
			{ key: 'id'},
			{ key: 'userName'},
		  { key: 'LastLoggedIn' },
		  { key: 'LoginStatus' },
		  { key: 'suitAddress' },
		  { key: 'StatusOfEmailComfirmationLink' },
		  { key: 'WarehouseItems'}
		],
		skip:0,
		from:0,
		to:0,
		searchQuery: '',
		allCustomers: ''
	  }
	},
	async mounted (){
		let query = {
		query: {
			role: {
				$nin : ['SUPERADMIN', 'OPERATOR']
			},
	     }
        }
	    query["query"]["fields"] = ["customerDetails"]
	    await this.getCustomerDetails(query)
		this.allCustomers = this.customerDetails
		query = {
			query: {
			role: {
				$nin : ['SUPERADMIN', 'OPERATOR']
			},
			$limit: this.perPage,
			$skip: (this.currentPage-1) * this.perPage
	     }
        }
		query["query"]["fields"] = ["customerDetails"]
	    await this.getCustomerDetails(query)
	},
	
	computed:{
	  ...mapState({
	   customerDetails: (state) =>{
		 return state.customerDetails.customerDetails;
	   },
	  })
	},
	watch:{
	  currentPage:{
		handler(){
		  this.skip = (this.currentPage - 1) * this.perPage
		  this.reload()
		}
	  },
	  perPage:{
		handler(){
		  this.skip = (this.currentPage - 1) * this.perPage
		  this.reload()
		}
	  },
	  categories:{
		handler(){
		  const carouselLength = this.carousel.length;
		  this.from = this.perPage * (this.currentPage - 1) + (carouselLength ? 1 : 0);
		  this.to =  this.perPage * (this.currentPage - 1) + carouselLength;
		}
	  },
	  searchQuery: {
		handler() {
			if(this.searchQuery == ''){
				this.reload2()
			}
			else{
			    this.reload()
			}
		}
	  }
	},
	methods:{
	  ...mapActions({
		getCustomerDetails: "customerDetails/getCustomerDetails",
		getItemsInUsersSuite: "customerDetails/getWarehouseItemsInUsersSuite"
	  }),
	  async reload(){
		let query = {
		query: {
			role: {
				$nin : ['SUPERADMIN', 'OPERATOR']
			},
			$limit: this.perPage,
			$skip: (this.currentPage-1) * this.perPage
	     }
        }
	    query["query"]["fields"] = ["customerDetails"]
		if(this.searchQuery!=''){
		    query["query"]["$search"] = this.searchQuery
		    query["query"]["$customerDetailsSearch"] = true
		}
		console.log("query while searching customer details", query);
		await this.getCustomerDetails(query)
		if(this.searchQuery!=''){
		   this.allCustomers = this.getCustomerDetails
		}
      },
	  async reload2(){
		let query = {
		query: {
			role: {
				$nin : ['SUPERADMIN', 'OPERATOR']
			},
	     }
        }
	    query["query"]["fields"] = ["customerDetails"]
	    await this.getCustomerDetails(query)
		this.allCustomers = this.customerDetails
		query = {
			query: {
			role: {
				$nin : ['SUPERADMIN', 'OPERATOR']
			},
			$limit: this.perPage,
			$skip: (this.currentPage-1) * this.perPage
	     }
        }
		query["query"]["fields"] = ["customerDetails"]
	    await this.getCustomerDetails(query)
	  },
	  async showItems(val){
		await this.getItemsInUsersSuite({ id:val.id })
		this.$refs.showItemsInWarehouse.showModal()
	  }
	},
	components: {
	  BCard,
	  BRow,
	  BCol,
	  BFormInput,
	  BButton,
	  BTable,
	  BMedia,
	  BAvatar,
	  BLink,
	  BBadge,
	  BDropdown,
	  BDropdownItem,
	  BPagination,
	  BPopover,
	  vSelect,
	  BCardCode,
	  SuitItems
	},
  
  }
  
  </script>
  <style>
  .card-header {
	background-color:#ebe9f1;
  }
  .image {
    width: 60px;
    height: 58px;
  }
  .search_bar {
	width: 20rem;
	margin-bottom: 2rem;
  }
  </style>
  